import axios from 'axios';

export const getIPAddress = async (): Promise<string> => {
  try {
    // Strip off axios interceptors
    const instance = axios.create();
    const clientInfo = await instance.get(process.env.VUE_APP_GET_IP_URL);
    var data = clientInfo.data.replace(/[\r\n]+/g, '","').replace(/=+/g, '":"');
    data = '{"' + data.slice(0, data.lastIndexOf('","')) + '"}';
    var jsondata = JSON.parse(data);
    return jsondata.ip;
  }
  catch (err) {
    return "Not defined by cloudflare";
  }
}