












































































































import { Component, Prop, Vue, Model } from "vue-property-decorator";
import ClaimVerify from "@/components/ClaimVerify.vue";
import EventType from "@/components/EventType.vue";
import LawyerDeductionsCosts from "@/components/LawyerDeductionsCosts.vue";
import LegalRepresentativeDetails from "@/components/LegalRepresentativeDetails.vue";
import router from "../router";

@Component({
  components: {
    EventType,
    ClaimVerify,
    LawyerDeductionsCosts,
    LegalRepresentativeDetails,
  },
})
export default class ClaimConfirm extends Vue {
  @Prop()
  private transactionId!: string;

  @Prop()
  private submittedAt!: Date;

  @Prop()
  private legalRepFirstName!: string;

  @Prop()
  private legalRepLastName!: string;

  @Prop()
  private legalRepPhoneNumber!: string;

  @Prop()
  private legalRepEmail!: string;
  @Prop()
  private managingInsurer!: string;

  @Prop()
  private claimId!: string;
  @Prop()
  private accidentDate!: Date;
  @Prop()
  private accidentPostCode!: string;
  @Prop()
  private claimantDateOfBirth!: Date;
  @Prop()
  private verificationStepPerformed!: string;

  @Prop()
  private estimateTotalCosts!: string;

  @Prop()
  private estimatePartyCosts!: string;

  @Prop()
  private estimateLegalFees!: string;

  @Prop()
  private advancePaymentClient!: string;

  @Prop()
  private solicitorClientFees!: string;

  @Prop()
  private partyCosts!: string;

  @Prop()
  private previousLawyerLegalFees!: string;

  @Prop()
  private barristerCounselFees!: string;

  @Prop()
  private attendingGPFees!: string;

  @Prop()
  private medicoLegalSpecialistReports!: string;

  @Prop()
  private otherExpertFees!: string;

  @Prop()
  private otherFees!: string;

  @Prop()
  private acatCostOrder!: string;

  @Prop()
  private netAmountDueClaimant!: string;

  @Prop()
  private claimantPaymentDate!: string;

  @Prop()
  private proceedingType!: string;

  @Prop()
  private decisionSettlementDate!: string;

  @Prop()
  private feedbackForMAIC!: string;

  @Prop()
  private insurerFirstSettlementOffer!: string;

  @Prop()
  private insurerFirstOfferInclusiveLegalCosts!: string;

  @Prop()
  private insurerFirstOfferDate!: string;

  @Prop()
  private claimantFirstSettlementOffer!: string;

  @Prop()
  private claimantFirstOfferInclusiveLegalCostDisbursements!: string;

  @Prop()
  private claimantFirstOfferDate!: string;

  @Prop()
  private medicarePayback!: string;
  @Prop()
  private centrelinkPayback!: string;
  @Prop()
  private privateHealthInsurerPayback!: string;

  @Prop()
  private pdfMode!: boolean;

  private formattedDate(): string {
    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    if (this.submittedAt)
      return this.submittedAt.toLocaleString("en-GB", options);

    return null;
  }
}
