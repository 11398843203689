

























































import { Component, Prop, Vue, Model } from "vue-property-decorator";
import { v1 as uuid } from "uuid";
import PortalBaseField from "./PortalBaseField.vue";

@Component
export default class PortalNumberField extends PortalBaseField {
  @Prop() private labelClass!: string;
  @Prop() private label!: string;
  @Model("change", { type: String }) readonly data!: string;
  @Prop() private md: string | number;
  @Prop() private lg: string | number;
  @Prop() private xl: string | number;
  @Prop() private readonly: string;
  @Prop() private tooltip: string;
  @Prop() private state: any;
  @Prop() private invalidFeedback: any;
  @Prop() private required: boolean;
  private show: boolean = false;
}
