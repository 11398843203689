<template>
  <div class="home pb-5">
    <vue-headful title="Details Submitted - MAIC Lawyer Portal" />
    <div class="row pt-3">
      <div class="col-md-10 col-md-offset-1">
        <div class="row">
          <div class="col">
            <p>
              Thank you for your submission. The information you provided has
              been lodged with the MAI Commission. Should you need to update any
              of the information provided, you are able to re-complete the
              process with the corrected data within 90 days of the decision
              date.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2 pb-5">
            <b-button @click="downloadWithCSS" variant="secondary"
              >Download PDF</b-button
            >
          </div>
          <div class="col">
            <b-button @click="submitAnother" variant="primary"
              >Submit another</b-button
            >
          </div>
        </div>

        <div
          ref="content"
          class="pdf pt-3"
          style="border: 1px solid rgb(12, 127, 137)"
        >
          <claim-confirm
            pdfMode="true"
            :transactionId="submission.transactionId"
            :submittedAt="submission.submittedAt"
            :legalRepFirstName="submission.legalRepFirstName"
            :legalRepLastName="submission.legalRepLastName"
            :legalRepPhoneNumber="submission.legalRepPhoneNumber"
            :legalRepEmail="submission.legalRepEmail"
            :managingInsurer="submission.managingInsurer"
            :claimId="submission.claimId"
            :accidentDate="submission.accidentDate"
            :accidentPostCode="submission.accidentPostCode"
            :claimantDateOfBirth="submission.claimantDateOfBirth"
            :estimateTotalCosts="submission.estimateTotalCosts"
            :estimatePartyCosts="submission.estimatePartyCosts"
            :estimateLegalFees="submission.estimateLegalFees"
            :solicitorClientFees="submission.solicitorClientFees"
            :partyCosts="submission.partyCosts"
            :previousLawyerLegalFees="submission.previousLawyerLegalFees"
            :barristerCounselFees="submission.barristerCounselFees"
            :attendingGPFees="submission.attendingGPFees"
            :medicoLegalSpecialistReports="
              submission.medicoLegalSpecialistReports
            "
            :otherExpertFees="submission.otherExpertFees"
            :otherFees="submission.otherFees"
            :acatCostOrder="submission.acatCostOrder"
            :netAmountDueClaimant="submission.netAmountDueClaimant"
            :claimantPaymentDate="submission.claimantPaymentDate"
            :proceedingType="submission.proceedingType"
            :decisionSettlementDate="submission.decisionSettlementDate"
            :feedbackForMAIC="submission.feedbackForMAIC"
            :insurerFirstOfferInclusiveLegalCosts="
              submission.insurerFirstOfferInclusiveLegalCosts
            "
            :insurerFirstSettlementOffer="
              submission.insurerFirstSettlementOffer
            "
            :claimantFirstSettlementOffer="
              submission.claimantFirstSettlementOffer
            "
            :insurerFirstOfferDate="submission.insurerFirstOfferDate"
            :claimantFirstOfferInclusiveLegalCostDisbursements="
              submission.claimantFirstOfferInclusiveLegalCostDisbursements
            "
            :claimantFirstOfferDate="submission.claimantFirstOfferDate"
            :medicarePayback="submission.medicarePayback"
            :centrelinkPayback="submission.centrelinkPayback"
            :privateHealthInsurerPayback="
              submission.privateHealthInsurerPayback
            "
            :advancePaymentClient="submission.advancePaymentClient"
          ></claim-confirm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../router";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ClaimConfirm from "@/components/ClaimConfirm.vue";
import { mapState } from "vuex";

export default {
  name: "claimSubmitted",
  components: {
    ClaimConfirm,
  },

  methods: {
    submitAnother() {
      router.push({ name: "submission", hash: "#step_1" });
    },
    async submitClaim(evt) {
      evt.preventDefault();
    },

    downloadWithCSS() {
      const doc = new jsPDF();
      /** WITH CSS */
      var canvasElement = document.createElement("canvas");
      html2canvas(this.$refs.content, { canvas: canvasElement }).then(function (
        canvas
      ) {
        const imgData = canvas.toDataURL("image/jpeg", 0.8);
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;
        var doc = new jsPDF("p", "mm");
        var position = 0;

        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save("MAICSubmission.pdf");
      });
    },
  },
  computed: {
    ...mapState({ submission: (state) => state.submissionStore }),
  },
};
</script>
