import { Container } from "inversify";
import { TYPES } from "./types";
import { ILawyerService } from '../services/ILawyer.service';
import { LawyerService } from '../services/lawyer.service';
import { MockLawyerService } from '../mocks/mockLawyerService';
import "reflect-metadata";

let container: Container = new Container();

if (process.env.VUE_APP_MOCK_LAWYER_SERVICE === 'true') {
    container.bind<ILawyerService>(TYPES.LawyerService).to(MockLawyerService);
}
else {
    container.bind<ILawyerService>(TYPES.LawyerService).to(LawyerService);
}


export default container;

