<template>
  <div id="app" v-cloak class="layout">
    <link
      rel="stylesheet"
      href="https://unpkg.com/vue-multiselect@2.1.0/dist/vue-multiselect.min.css"
    />
    <div class="content">
      <div class="row">
        <div class="col-md-12 no-padding">
          <header class="uikit-header uikit-header--light">
            <div class="col-md-1"></div>
            <div class="col-md-10">
              <div class="row">
                <div class="col-md-11">
                  <router-link
                    to="/"
                    title="Link to MAIC Lawyer Portal Homepage"
                    class="uikit-header__logo-wrapper"
                    aria-label="Home, Lawyer Portal - Motor Accident Injuries Commission - ACT Government logo"
                  >
                    <ul class="uikit-link-list uikit-link-list--inline">
                      <li role="presentation" class="uikit-header__logo">
                        <img
                          :src="`${publicPath}logo_140x72_ACTGov.png`"
                          alt="ACT Government logo"
                          class="uikit-header__logo-image"
                        />
                      </li>
                      <li role="presentation" class="uikit-header__logo">
                        <span class="uikit-header__logo-text">Motor Accident Injuries Commission</span>
                      </li>
                      <li role="presentation" class="uikit-header__logo">
                        <span class="uikit-header__logo-text">Lawyer Portal</span>
                      </li>
                    </ul>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-md-1"></div>
          </header>
        </div>
      </div>
      <div class="row" id="mainLogo" v-if="$route.name === 'home'">
        <div class="col-md-12 no-padding">
          <div
            class="spf-text-banner spf-text-banner--image-gradient aqua-indigo"
            :style="`background-image: url('${publicPath}banner-maic.jpg');`"
          >
            <div class="spf-text-banner--image-content">
              <div class="spf-text-banner--image-content-text">
                <h1>Motor Accident Injuries Commission</h1>
                <p>Lawyer Portal</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span aria-live="assertive" class="sr-only">{{loading}}</span>

      <div id="main"></div>
      <div class="row" v-if="$route.name !== 'home'">
        <div class="spf-text-banner spf-text-banner--gradient-aqua-indigo">
          <div class="col-md-10 col-md-offset-1 pt-4 pb-3">
            <h1 id="pageName" tabindex="-1">{{$route.meta.pageName}}</h1>
          </div>
        </div>
      </div>
      <router-view
        @updateLoadingFocus="updateLoadingFocus"
        @updateLoadingNoFocus="updateLoadingNoFocus"
      />
    </div>
    <footer class="uikit-grid uikit-footer spf-footer-global mt-4">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-12">
            <ul class="uikit-link-list uikit-link-list--inline uikit-link-list--inverted">
              <li class>
                <a
                  target="_blank"
                  href="https://apps.treasury.act.gov.au/maic/about-us"
                  title="Link to About Us"
                  style="text-decoration: none;"
                >About us</a>
              </li>
              <li class>
                <a
                  target="_blank"
                  href="https://apps.treasury.act.gov.au/maic/about-us/contact-us"
                  title="Link to Contact Us"
                  class
                >Contact us</a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.cmtedd.act.gov.au/functions/accessibility"
                  title="Link to Accessibility"
                  class
                >Accessibility</a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.cmtedd.act.gov.au/legal/privacy"
                  title="Link to Privacy"
                  class
                >Privacy</a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.cmtedd.act.gov.au/legal/disclaimer"
                  title="Link to Disclaimer"
                  class
                >Disclaimer</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      loading: ""
    };
  },
  methods: {
    updateLoadingFocus(focusId) {
      this.updateLoading(true, focusId);
    },
    updateLoadingNoFocus() {
      this.updateLoading();
    },
    updateLoading(focus, focusId) {
      this.loading = "Page loading";
      const me = this;
      const currentFocus = document.activeElement;
      setTimeout(function() {
        me.loading = "";
        if (focus && currentFocus === document.activeElement) {
          var ctl;
          if (focusId) {
            ctl = document.getElementById(focusId);
          } else ctl = document.getElementById("pageName");
          if (ctl) {
            ctl.focus();
          }
        }
      }, 5000);
    }
  },
  watch: {
    $route(to, from) {
      this.updateLoading(true);
    }
  }
};
</script>

<style lang="scss">
@import "assets/base-style.scss";
</style>
