



































































import { Component, Prop, Vue, Model } from "vue-property-decorator";
import { v1 as uuid } from "uuid";
import moment from "moment";
import PortalBaseField from "./PortalBaseField.vue";

@Component
export default class PortalDateField extends PortalBaseField {
  @Prop() private labelClass!: string;
  @Prop() private label!: string;
  @Model("change", { type: Date }) readonly data!: Date;
  @Prop() private md: string | number;
  @Prop() private lg: string | number;
  @Prop() private xl: string | number;
  @Prop() private readonly: string;
  @Prop() private tooltip: string;
  @Prop() private state: any;
  @Prop() private invalidFeedback: any;
  @Prop() private required: boolean;

  @Prop() private datepicker: string;

  formatted(date: string) {
    try {
      if (date) return moment(date, "DD/MM/YYYY").toDate();
      else return null;
    } catch {
      return null;
    }
  }

  format(date: Date) {
    try {
      if (date) return new Intl.DateTimeFormat("en-GB").format(date);
      else return null;
    } catch {
      return null;
    }
  }
}
