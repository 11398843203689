import { helpers } from 'vuelidate/lib/validators'
import findKey from "lodash/findKey";
import PortalBaseField from '../components/PortalBaseField.vue'

export const money = value => {
    if (typeof value === "undefined" || value === null || value === "") {
        return true;
    }
    return /^\d+(?:\.\d{0,2})?$/.test(value);
};

export const date = date =>
    helpers.withParams(
        { type: 'minDate', min: date },
        value => !helpers.req(value) || value.toISOString().substring(0, 10) < date
    )

export const focusFirstError = ($v, $refs) => {
    const _ = { findKey };
    const invalidField = _.findKey(
        $v.$params,
        (value, key) => $v[key].$invalid
    );

    if (invalidField) {
        let inputField = $refs[invalidField] as PortalBaseField;
        if (inputField) {
            document.getElementById(inputField.id).focus();
        }
    }

}