
import { Component, Vue } from "vue-property-decorator";
import { v1 as uuid } from "uuid";

@Component
export default class PortalBaseField extends Vue {
  id: string =
    "g" +
    uuid()
      .toString()
      .substring(0, 8);

  protected tooltipid: string = "t" + this.id;
  protected containerid: string = "c" + this.id;
}
