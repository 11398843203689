





































































































































































































































































import { Component, Prop, Vue, Model } from "vue-property-decorator";
import PortalBooleanField from "@/components/PortalBooleanField.vue";
import PortalDateField from "@/components/PortalDateField.vue";
import PortalMoneyField from "@/components/PortalMoneyField.vue";
import PortalNumberField from "@/components/PortalNumberField.vue";
import PortalTextField from "@/components/PortalTextField.vue";
import { email, required, sameAs, minLength } from "vuelidate/lib/validators";
import { Validate, Validations } from "vuelidate-property-decorators";
import { date, focusFirstError, money } from "@/common/validation";

@Component({
  components: {
    PortalBooleanField,
    PortalDateField,
    PortalMoneyField,
    PortalNumberField,
    PortalTextField,
  },
})
export default class EventType extends Vue {
  @Validations()
  validations() {
    let validations = {} as any;
    if (this.isCommonLaw) {
      validations = {
        proceedingType: { required },
        decisionSettlementDate: {
          required,
          date: date(new Date().toISOString().substring(0, 10)),
        },
        insurerFirstSettlementOffer: { required, money },
        insurerFirstOfferInclusiveLegalCosts: { required },
        insurerFirstOfferDate: {
          required,
          date: date(new Date().toISOString().substring(0, 10)),
        },
        claimantFirstSettlementOffer: { required, money },
        claimantFirstOfferInclusiveLegalCostDisbursements: { required },
        claimantFirstOfferDate: {
          required,
          date: date(new Date().toISOString().substring(0, 10)),
        },
      };
    } else {
      validations = {
        proceedingType: { required },
        decisionSettlementDate: {
          required,
          date: date(new Date().toISOString().substring(0, 10)),
        },
      };
    }
    if (this.claimUnverified) {
      validations.simpleAnswer = {
        required,
        sameAs: sameAs("correctAnswer"),
      };
    }
    return validations;
  }
  @Prop()
  private number1!: number;

  @Prop()
  private number2!: number;

  @Prop()
  private correctAnswer;

  @Prop()
  private lastNameSpamFilter!: string;

  @Prop()
  private claimUnverified!: boolean;

  @Prop()
  private simpleAnswer: string;

  @Prop()
  private feedbackForMAIC!: string;

  @Prop()
  private insurerFirstSettlementOffer!: string;

  @Prop()
  private insurerFirstOfferInclusiveLegalCosts!: string;

  @Prop()
  private insurerFirstOfferDate!: string;

  @Prop()
  private claimantFirstSettlementOffer!: string;

  @Prop()
  private claimantFirstOfferInclusiveLegalCostDisbursements!: string;

  @Prop()
  private claimantFirstOfferDate!: string;

  @Prop() private readonly: string;

  private $v: any;

  proceedingTypeOptions: Array<any> = [
    { value: "", text: "" },
    { value: "A", text: "ACAT Review" },
    { value: "C", text: "Common Law" },
  ];

  get simpleLabel(): string {
    return `What is ${this.number1} + ${this.number2}?`;
  }

  @Prop()
  proceedingType: string;

  @Prop()
  decisionSettlementDate: string;

  validate() {
    this.$v.$touch();
    var isValid = !this.$v.$invalid;
    if (!isValid) focusFirstError(this.$v, this.$refs);
    this.$emit("on-validate", this.$data, isValid);
    return Promise.resolve(isValid);
  }

  validateState(name) {
    if (name) {
      return name.$dirty ? !name.$error : null;
    } else {
      return null;
    }
  }

  get isCommonLaw(): boolean {
    return this.proceedingType === "C";
  }
}
