

































































import { Component, Prop, Vue, Model } from "vue-property-decorator";
import PortalDataField from "@/components/PortalDataField.vue";
import PortalBaseField from "@/components/PortalBaseField.vue";
import { email, required, sameAs, minLength } from "vuelidate/lib/validators";
import { Validate } from "vuelidate-property-decorators";
import { focusFirstError } from "@/common/validation.ts";

@Component({
  components: {
    PortalDataField,
  },
})
export default class LegalRepresentativeDetails extends Vue {
  @Validate({ required })
  @Prop()
  private legalRepFirstName!: string;

  @Validate({ required })
  @Prop()
  private legalRepLastName!: string;

  @Validate({ required })
  @Prop()
  private legalRepPhoneNumber!: string;

  @Validate({ required, email })
  @Prop()
  private legalRepEmail!: string;

  @Prop() private readonly: string;

  private $v: any;

  async validate() {
    this.$v.$touch();
    var isValid = !this.$v.$invalid;
    if (!isValid) focusFirstError(this.$v, this.$refs);
    this.$emit("on-validate", this.$data, isValid);
    return Promise.resolve(isValid);
  }

  validateState(name) {
    return name.$dirty ? !name.$error : null;
  }
}
