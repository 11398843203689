import Vue from 'vue'
import Vuex from 'vuex'
import submissionStore from './submission'
import vuexLocal from './localStorage';

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        submissionStore
    },
    plugins: [vuexLocal.plugin]

})