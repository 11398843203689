<template>
  <div class="home pb-5">
    <vue-headful title="Home - MAIC Lawyer Portal" />
    <div class="row pt-3">
      <div class="col-md-10 col-md-offset-1">
        <div class="row">
          <div class="col">
            <p>
              The Motor Accident Injuries Commission Lawyer Portal (“Lawyer
              Portal”) is designed to collect information for the purposes of
              the
              <i
                >Motor Accident Injuries (Legal Information Collection)
                Regulation 2021</i
              >. The persons who are or were the legal representatives of an
              applicant or claimant ("client") have an obligation to provide
              this information. The information may also be provided to the
              portal by a person authorised by the legal representative to
              provide the information.
            </p>
            <p>
              The information that is being provided to the portal is protected
              information under the <i>Motor Accident Injuries Act 2019</i> and
              associated regulations. This website has been authorised by the
              MAI Commission.
            </p>
            <p>
              You agree when providing information on the portal that you are
              the appropriate person (including an authorised person) to provide
              the information and that the information you provide is true and
              correct. If you come into information located on the portal, you
              agree not to release or otherwise deal with information from the
              Lawyer Portal and inform the MAI Commission immediately.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <b-form @submit="agree" class="pt-2">
              <p>
                By clicking Proceed you agree to the above terms and conditions.
              </p>
              <b-form-row>
                <b-col>
                  <b-button type="submit" variant="primary">Proceed</b-button>
                </b-col>
              </b-form-row>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../router";

export default {
  name: "home",
  methods: {
    async agree(evt) {
      evt.preventDefault();
      router.push({ name: "submission" });
    },
  },
};
</script>
