<template>
  <div class="home">
    <vue-headful title="Submit Details - MAIC Lawyer Portal" />
    <div class="row pb-5 pt-3">
      <div class="col col-md-12 col-lg-8 offset-lg-2">
        <div class="row">
          <div class="col">
            <form-wizard
              color="rgb(0, 130, 140)"
              finish-button-text="Submit"
              @on-complete="onComplete"
              @on-change="onChange"
              :prevTab="prevTab"
              stepSize="sm"
              ref="formWizard"
            >
              <span slot="title"></span>
              <template slot="step" slot-scope="props">
                <custom-wizard-step
                  :tab="props.tab"
                  :transition="props.transition"
                  :step-size="props.stepSize"
                  @click.native="props.navigateToTab(props.index)"
                  @keyup.enter.native="props.navigateToTab(props.index)"
                  :key="props.tab.title"
                  :index="props.index"
                ></custom-wizard-step>
              </template>
              <template slot="footer" slot-scope="props">
                <div class="wizard-footer-left">
                  <span
                    @click="prevTab()"
                    @keyup.enter="prevTab()"
                    v-if="props.activeTabIndex !== 0"
                    role="button"
                    tabindex="0"
                  >
                    <wizard-button
                      :style="props.fillButtonStyle"
                      :disabled="props.loading"
                      >Back</wizard-button
                    >
                  </span>
                  <span v-if="verificationStepPerformed && showReset">
                    <span class="pl-5">
                      <b-button v-b-modal.modal-reset variant="danger"
                        >Reset</b-button
                      >
                      <b-modal
                        size="lg"
                        id="modal-reset"
                        ok-title="Yes"
                        cancel-title="No"
                        okVariant="danger"
                        @ok="resetClaim"
                      >
                        <slot name="modal-title">
                          <h5
                            class="modal-title"
                            tabindex="-1"
                            id="modal-reset-header"
                          >
                            Reset verified claim
                          </h5>
                        </slot>

                        <p>
                          Resetting the verified claim will lose all data
                          entered in subsequent steps. Are you sure?
                        </p>
                      </b-modal>
                    </span>
                  </span>
                </div>

                <div class="wizard-footer-right">
                  <span
                    @click="nextTab()"
                    @keyup.enter="nextTab()"
                    v-if="props.isLastStep"
                    role="button"
                    tabindex="0"
                  >
                    <wizard-button :style="props.fillButtonStyle"
                      >Submit</wizard-button
                    >
                  </span>
                  <span
                    @click="nextTab()"
                    @keyup.enter="nextTab"
                    role="button"
                    tabindex="0"
                    v-else
                  >
                    <wizard-button
                      :style="props.fillButtonStyle"
                      :disabled="props.loading"
                      >Next</wizard-button
                    >
                  </span>
                </div>
              </template>

              <tab-content
                title="Legal representative"
                :before-change="
                  () => validateStep('legalRepresentativeDetails')
                "
              >
                <div class="row">
                  <div class="col-lg-8 col-lg-offset-2">
                    <legal-representative-details
                      :legalRepFirstName="legalRepFirstName"
                      @legal-rep-first-name-change="legalRepFirstName = $event"
                      :legalRepLastName="legalRepLastName"
                      @legal-rep-last-name-change="legalRepLastName = $event"
                      :legalRepPhoneNumber="legalRepPhoneNumber"
                      @legal-rep-phone-number-change="
                        legalRepPhoneNumber = $event
                      "
                      :legalRepEmail="legalRepEmail"
                      @legal-rep-email-change="legalRepEmail = $event"
                      ref="legalRepresentativeDetails"
                      readonly="false"
                    />
                  </div>
                </div>
              </tab-content>
              <tab-content
                title="Verification"
                :before-change="() => validateStep('claimVerify')"
              >
                <div class="row">
                  <div class="col-lg-8 col-lg-offset-2">
                    <claim-verify
                      ref="claimVerify"
                      :managingInsurer="managingInsurer"
                      @managing-insurer-change="managingInsurer = $event"
                      :claimId="claimId"
                      @claim-id-change="claimId = $event"
                      :accidentDate="accidentDate"
                      @accident-date-change="accidentDate = $event"
                      :accidentPostCode="accidentPostCode"
                      @accident-post-code-change="accidentPostCode = $event"
                      :claimantDateOfBirth="claimantDateOfBirth"
                      @claimant-dateofbirth-change="
                        claimantDateOfBirth = $event
                      "
                      :verificationStepPerformed="verificationStepPerformed"
                      :secret="secret"
                      @secretChange="secret = $event"
                      readonly="false"
                      :claimUnverified="claimUnverified"
                      :correctAnswer="correctAnswer"
                      :simpleAnswer="simpleAnswer"
                      @simple-answer-change="simpleAnswer = $event"
                    />
                  </div>
                </div>
              </tab-content>
              <tab-content
                title="Event type"
                :before-change="() => validateStep('eventType')"
              >
                <div class="row">
                  <div class="col-lg-8 col-lg-offset-2">
                    <event-type
                      ref="eventType"
                      :proceedingType="proceedingType"
                      @proceeding-type-change="proceedingType = $event"
                      :decisionSettlementDate="decisionSettlementDate"
                      @decision-settlement-date-change="
                        decisionSettlementDate = $event
                      "
                      :feedbackForMAIC="feedbackForMAIC"
                      @feedback-for-maic-change="feedbackForMAIC = $event"
                      :insurerFirstOfferInclusiveLegalCosts="
                        insurerFirstOfferInclusiveLegalCosts
                      "
                      @insurer-first-offer-inclusive-legal-costs-change="
                        insurerFirstOfferInclusiveLegalCosts = $event
                      "
                      :insurerFirstSettlementOffer="insurerFirstSettlementOffer"
                      @insurer-first-settlement-offer-change="
                        insurerFirstSettlementOffer = $event
                      "
                      :claimantFirstSettlementOffer="
                        claimantFirstSettlementOffer
                      "
                      @claimant-first-settlement-offer-change="
                        claimantFirstSettlementOffer = $event
                      "
                      :insurerFirstOfferDate="insurerFirstOfferDate"
                      @insurer-first-offer-date-change="
                        insurerFirstOfferDate = $event
                      "
                      :claimantFirstOfferInclusiveLegalCostDisbursements="
                        claimantFirstOfferInclusiveLegalCostDisbursements
                      "
                      @claimant-first-offer-inclusive-legal-cost-change="
                        claimantFirstOfferInclusiveLegalCostDisbursements =
                          $event
                      "
                      :claimantFirstOfferDate="claimantFirstOfferDate"
                      @claimant-first-offer-date-change="
                        claimantFirstOfferDate = $event
                      "
                      readonly="false"
                      :claimUnverified="claimUnverified"
                      :number1="number1"
                      :number2="number2"
                      :correctAnswer="correctAnswer"
                      :simpleAnswer="simpleAnswer"
                      @simple-answer-change="simpleAnswer = $event"
                      :lastNameSpamFilter="lastNameSpamFilter"
                      @last-name-spam-filter-change="
                        lastNameSpamFilter = $event
                      "
                    />
                  </div>
                </div>
              </tab-content>
              <tab-content
                title="Costs and disbursements"
                :before-change="() => validateStep('lawyerDeductionCosts')"
              >
                <div class="row">
                  <div class="col-lg-8 col-lg-offset-2">
                    <lawyer-deductions-costs
                      ref="lawyerDeductionCosts"
                      :proceedingType="proceedingType"
                      :estimateTotalCosts="estimateTotalCosts"
                      @estimate-total-costs-change="estimateTotalCosts = $event"
                      :estimatePartyCosts="estimatePartyCosts"
                      @estimate-party-costs-change="estimatePartyCosts = $event"
                      :estimateLegalFees="estimateLegalFees"
                      @estimate-legal-fees-change="estimateLegalFees = $event"
                      :solicitorClientFees="solicitorClientFees"
                      @solicitor-client-fees-change="
                        solicitorClientFees = $event
                      "
                      :partyCosts="partyCosts"
                      @party-costs-change="partyCosts = $event"
                      :previousLawyerLegalFees="previousLawyerLegalFees"
                      @previous-lawyer-legal-fees-change="
                        previousLawyerLegalFees = $event
                      "
                      :barristerCounselFees="barristerCounselFees"
                      @barrister-counsel-fees-change="
                        barristerCounselFees = $event
                      "
                      :attendingGPFees="attendingGPFees"
                      @attending-gp-fees-change="attendingGPFees = $event"
                      :medicoLegalSpecialistReports="
                        medicoLegalSpecialistReports
                      "
                      @medico-legal-specialist-reports-change="
                        medicoLegalSpecialistReports = $event
                      "
                      :otherExpertFees="otherExpertFees"
                      @other-expert-fees-change="otherExpertFees = $event"
                      :otherFees="otherFees"
                      @other-fees-change="otherFees = $event"
                      :acatCostOrder="acatCostOrder"
                      @acat-cost-order-change="acatCostOrder = $event"
                      :netAmountDueClaimant="netAmountDueClaimant"
                      @net-amount-due-claimant-change="
                        netAmountDueClaimant = $event
                      "
                      :claimantPaymentDate="claimantPaymentDate"
                      @claimant-payment-date-change="
                        claimantPaymentDate = $event
                      "
                      :medicarePayback="medicarePayback"
                      @medicare-payback-change="medicarePayback = $event"
                      :centrelinkPayback="centrelinkPayback"
                      @centrelink-payback-change="centrelinkPayback = $event"
                      :privateHealthInsurerPayback="privateHealthInsurerPayback"
                      @private-health-insurer-payback-change="
                        privateHealthInsurerPayback = $event
                      "
                      :advancePaymentClient="advancePaymentClient"
                      @advance-payment-client-change="
                        advancePaymentClient = $event
                      "
                      readonly="false"
                    />
                  </div>
                </div>
              </tab-content>
              <tab-content title="Confirm details">
                <div class="row">
                  <div class="col-lg-8 col-lg-offset-2">
                    <claim-confirm
                      :legalRepFirstName="legalRepFirstName"
                      :legalRepLastName="legalRepLastName"
                      :legalRepPhoneNumber="legalRepPhoneNumber"
                      :legalRepEmail="legalRepEmail"
                      :managingInsurer="managingInsurer"
                      :claimId="claimId"
                      :accidentDate="accidentDate"
                      :accidentPostCode="accidentPostCode"
                      :claimantDateOfBirth="claimantDateOfBirth"
                      :estimateTotalCosts="estimateTotalCosts"
                      :estimatePartyCosts="estimatePartyCosts"
                      :estimateLegalFees="estimateLegalFees"
                      :solicitorClientFees="solicitorClientFees"
                      :partyCosts="partyCosts"
                      :previousLawyerLegalFees="previousLawyerLegalFees"
                      :barristerCounselFees="barristerCounselFees"
                      :attendingGPFees="attendingGPFees"
                      :medicoLegalSpecialistReports="
                        medicoLegalSpecialistReports
                      "
                      :otherExpertFees="otherExpertFees"
                      :otherFees="otherFees"
                      :acatCostOrder="acatCostOrder"
                      :netAmountDueClaimant="netAmountDueClaimant"
                      :claimantPaymentDate="claimantPaymentDate"
                      :proceedingType="proceedingType"
                      :decisionSettlementDate="decisionSettlementDate"
                      :feedbackForMAIC="feedbackForMAIC"
                      :insurerFirstOfferInclusiveLegalCosts="
                        insurerFirstOfferInclusiveLegalCosts
                      "
                      :insurerFirstSettlementOffer="insurerFirstSettlementOffer"
                      :claimantFirstSettlementOffer="
                        claimantFirstSettlementOffer
                      "
                      :insurerFirstOfferDate="insurerFirstOfferDate"
                      :claimantFirstOfferInclusiveLegalCostDisbursements="
                        claimantFirstOfferInclusiveLegalCostDisbursements
                      "
                      :claimantFirstOfferDate="claimantFirstOfferDate"
                      :medicarePayback="medicarePayback"
                      :centrelinkPayback="centrelinkPayback"
                      :privateHealthInsurerPayback="privateHealthInsurerPayback"
                      :advancePaymentClient="advancePaymentClient"
                    />
                  </div>
                </div>
              </tab-content>
            </form-wizard>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="lodgementError" ok-only>
      <slot name="modal-title">
        <h5 class="modal-title" tabindex="-1" id="lodgementError-header">
          Unknown error
        </h5>
      </slot>

      <p class="my-4">
        An unknown error occurred submitting the information. Please wait 5
        minutes and try again.
      </p>
      <p class="my-4">
        Please contact the Motor Accident Injuries Register if there are
        repeated failures
        <a href="mailto:mair@act.gov.au?subject=Unknown%20error"
          >mair@act.gov.au</a
        >
      </p>
    </b-modal>
    <b-modal id="modal-timeout" ok-only>
      <slot name="modal-title">
        <h5 class="modal-title" tabindex="-1" id="modal-timeout-header">
          Timeout
        </h5>
      </slot>
      <p class="my-4">There was an error communicating with the servers.</p>
      <p class="my-4">
        Please try again or contact the Motor Accident Injuries Register at
        <a href="mailto:mair@act.gov.au?subject=Timeout">mair@act.gov.au</a>
      </p>
    </b-modal>
    <b-modal id="modal-notFound" ok-only>
      <slot name="modal-title">
        <h5 class="modal-title" tabindex="-1" id="modal-notFound-header">
          Application not found
        </h5>
      </slot>
      <p class="my-4">
        An application could not be found for the information provided.
      </p>
      <p class="my-4">
        Click
        <strong>Back</strong> to return to the Verification step to review the
        data you've provided or click <strong>Next</strong> to proceed.
      </p>
      <template v-slot:modal-footer>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-row style="width: 100%">
          <b-col cols="1">
            <b-button @click="back()" class="btnModal" variant="info"
              >Back</b-button
            >
          </b-col>
          <b-col offset="9" cols="1">
            <b-button @click="next()" class="btnModal" variant="info"
              >Next</b-button
            >
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <b-modal id="modal-tooMany" ok-only>
      <slot name="modal-title">
        <h5 class="modal-title" tabindex="-1" id="modal-tooMany-header">
          Multiple applications
        </h5>
      </slot>
      <p class="my-4">
        Multiple applications were found for the information provided.
      </p>
      <p class="my-4">
        Please contact the Motor Accident Injuries Register at
        <a href="mailto:mair@act.gov.au?subject=Multiple%20applications%20found"
          >mair@act.gov.au</a
        >
      </p>
    </b-modal>
  </div>
</template>

<script>
import router from "../router";
import { FormWizard, TabContent, WizardButton } from "vue-form-wizard";
import ClaimConfirm from "@/components/ClaimConfirm.vue";
import CustomWizardStep from "@/components/CustomWizardStep.vue";
import LawyerDeductionsCosts from "@/components/LawyerDeductionsCosts.vue";
import ClaimVerify from "@/components/ClaimVerify.vue";
import EventType from "@/components/EventType.vue";
import LegalRepresentativeDetails from "@/components/LegalRepresentativeDetails.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import "reflect-metadata";
import IOCContainer from "@/common/inversify.config";
import { TYPES } from "@/common/types";
import { ILawyerService } from "../services/ILawyer.service";
import "../common/maisLawyerServiceInterface";
import { SAVE_SUBMISSION } from "../stores/types";
import { mapMutations, mapState } from "vuex";

function staticState() {
  let state = {};
  if (process.env.VUE_APP_PREPOPULATE_DATA === "true") {
    state = {
      legalRepFirstName: "first",
      legalRepLastName: "last",
      legalRepPhoneNumber: "1234",
      legalRepEmail: "a@a.com",
    };
  } else {
    state = {
      legalRepFirstName: null,
      legalRepLastName: null,
      legalRepPhoneNumber: null,
      legalRepEmail: null,
    };
  }
  state.number1 = Math.floor(Math.random() * Math.floor(5)) + 1;
  state.number2 = Math.floor(Math.random() * Math.floor(5)) + 1;
  state.correctAnswer = null;
  state.simpleAnswer = null;
  return state;
}

function initialState() {
  if (process.env.VUE_APP_PREPOPULATE_DATA === "true") {
    return {
      transactionId: null,
      submittedAt: null,
      managingInsurer: "NRMA",
      claimId: "CLID2a",
      accidentDate: new Date(2019, 0, 20),
      accidentPostCode: "2612",
      claimantDateOfBirth: new Date(2000, 1, 29),
      estimateTotalCosts: "1.00",
      estimatePartyCosts: "1.00",
      estimateLegalFees: "12.00",
      solicitorClientFees: "1.00",
      partyCosts: "1.00",
      previousLawyerLegalFees: "1.00",
      barristerCounselFees: "1.00",
      attendingGPFees: "1.00",
      medicoLegalSpecialistReports: "1.00",
      otherExpertFees: "1.00",
      otherFees: "2.00",
      acatCostOrder: "Y",
      netAmountDueClaimant: "1000.00",
      claimantPaymentDate: new Date(2020, 1, 1),
      proceedingType: "A",
      decisionSettlementDate: new Date(2020, 2, 2),
      feedbackForMAIC: "They rock",
      insurerFirstOfferInclusiveLegalCosts: null,
      insurerFirstSettlementOffer: null,
      insurerFirstOfferDate: null,
      claimantFirstSettlementOffer: null,

      claimantFirstOfferInclusiveLegalCostDisbursements: null,
      claimantFirstOfferDate: null,
      medicarePayback: null,
      centrelinkPayback: null,
      otherInsurerPayback: null,
      advancePaymentClient: null,
      privateHealthInsurerPayback: null,
      verificationStepPerformed: false,
      secret: null,
      showReset: false,
      claimUnverified: false,
      lastNameSpamFilter: null,
    };
  } else {
    return {
      transactionId: null,
      submittedAt: null,
      managingInsurer: null,
      claimId: null,
      accidentDate: null,
      accidentPostCode: null,
      claimantDateOfBirth: null,
      estimateTotalCosts: null,
      estimatePartyCosts: null,
      estimateLegalFees: null,
      solicitorClientFees: null,
      partyCosts: null,
      previousLawyerLegalFees: null,
      barristerCounselFees: null,
      attendingGPFees: null,
      medicoLegalSpecialistReports: null,
      otherExpertFees: null,
      otherFees: null,
      acatCostOrder: null,
      medicarePayback: null,
      centrelinkPayback: null,
      otherInsurerPayback: null,
      advancePaymentClient: null,
      privateHealthInsurerPayback: null,
      netAmountDueClaimant: null,
      claimantPaymentDate: null,
      proceedingType: "",
      decisionSettlementDate: null,
      feedbackForMAIC: null,
      insurerFirstOfferInclusiveLegalCosts: null,
      insurerFirstSettlementOffer: null,
      insurerFirstOfferDate: null,
      claimantFirstSettlementOffer: null,
      claimantFirstOfferInclusiveLegalCostDisbursements: null,
      claimantFirstOfferDate: null,
      verificationStepPerformed: false,
      secret: null,
      showReset: false,
      claimUnverified: false,
      lastNameSpamFilter: null,
    };
  }
}

export default {
  name: "submission",

  components: {
    FormWizard,
    CustomWizardStep,
    ClaimConfirm,
    WizardButton,
    LawyerDeductionsCosts,
    ClaimVerify,
    EventType,
    LegalRepresentativeDetails,
    TabContent,
  },
  data() {
    return { ...initialState(), ...staticState() };
  },
  methods: {
    ...mapMutations([SAVE_SUBMISSION]),
    next: function () {
      this.$bvModal.hide("modal-notFound");
      this.claimUnverified = true;
      this.simpleAnswer = null;

      this.$nextTick(function () {
        this.nextTab();
      });
    },

    back: function () {
      this.$bvModal.hide("modal-notFound");
    },

    prevTab: function () {
      const formWizard = this.$refs["formWizard"];
      const activeTab = formWizard.activeTabIndex;
      formWizard.prevTab();
      this.$emit("updateLoadingFocus", "header-" + activeTab.toString());
    },
    nextTab: function () {
      const formWizard = this.$refs["formWizard"];
      const activeTab = formWizard.activeTabIndex + 2;
      formWizard.nextTab();
      this.$emit("updateLoadingFocus", "header-" + activeTab.toString());
    },
    onComplete: function () {
      let lawyerService = IOCContainer.get(TYPES.LawyerService);
      const me = this;
      try {
        lawyerService
          .submitLawyerLodgement(me.$data, me.$data.claimUnverified)
          .then(
            function (value) {
              if (value.data.success) {
                me.$data.transactionId = value.data.transactionId;
                me.$data.submittedAt = value.data.submittedAt;
                me.SAVE_SUBMISSION(me.$data);
                me.simpleAnswer = null;
                me.number1 = Math.floor(Math.random() * Math.floor(5)) + 1;
                me.number2 = Math.floor(Math.random() * Math.floor(5)) + 1;
                me.correctAnswer = me.number1 + me.number2;
                me.simpleAnswer = me.correctAnswer;

                router.push({ name: "claimSubmitted" });
              } else {
                me.$bvModal.show("lodgementError");
              }
            },
            function (e) {
              console.log(JSON.stringify(e));
              me.$bvModal.show("lodgementError");
            }
          );
      } catch {
        this.$bvModal.show("lodgementError");
      }
    },
    onChange: function (prevIndex, nextIndex) {
      this.$emit("updateLoadingNoFocus");
      this.showReset = nextIndex === 1;
    },
    resetClaim: function () {
      Object.assign(this.$data, initialState());
      this.claimUnverified = false;
    },
    validateStep(name) {
      return new Promise((resolve, reject) => {
        var refToValidate = this.$refs[name];
        const me = this;
        refToValidate.validate().then(function (isValid) {
          if (isValid && name === "claimVerify") {
            me.verificationStepPerformed = true;
          }
          return resolve(isValid);
        });
      });
    },
    confirmLeave(event) {
      const answer = window.confirm(
        "Do you really want to leave? you have unsaved changes!"
      );
      if (event) event.preventDefault();
      return answer;
    },
  },
  computed: {
    ...mapState({ submission: (state) => state.submissionStore }),
  },

  mounted: function () {
    this.correctAnswer = (this.number1 + this.number2).toString();
    this.simpleAnswer = this.correctAnswer;
    //Object.assign(this.$data, initialState());
    this.$root.$on("bv::modal::shown", (bvEvent, modalId) => {
      const header = document.getElementById(modalId + "-header");
      if (header) {
        header.focus();
      } else console.log(modalId + "-header not found");
    });
    if (this.$route.hash.length > 0) {
      this.$data.legalRepFirstName = this.submission.legalRepFirstName;
      this.$data.legalRepLastName = this.submission.legalRepLastName;
      this.$data.legalRepPhoneNumber = this.submission.legalRepPhoneNumber;
      this.$data.legalRepEmail = this.submission.legalRepEmail;
    }
  },
  areYouSure: function (evt) {
    evt.returnValue = `Are you sure you want to leave?`;
  },
  destroyed: function () {
    window.removeEventListener("beforeunload", this.areYouSure);
  },
  created() {
    window.addEventListener("beforeunload", this.areYouSure);
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === "claimSubmitted" || to.name === "500") {
      next();
    } else {
      const answer = this.confirmLeave();
      if (answer) {
        next();
      } else {
        next(false);
      }
    }
  },
};
</script>
