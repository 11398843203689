var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home pb-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(_vm.readonly === 'false')?_c('h2',{attrs:{"id":"header-4","tabindex":"-1"}},[_vm._v(" 4. Lawyer costs and disbursements ")]):_vm._e(),(_vm.readonly === 'true')?_c('h3',[_vm._v("4. Lawyer costs and disbursements")]):_vm._e()])]),(_vm.readonly === 'false')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_vm._v(" Please enter the amount in dollars and cents or 0.00 if not applicable. ")])]):_vm._e(),_c('div',{staticClass:"row pt-3"},[_c('div',{staticClass:"col"},[(_vm.readonly === 'false')?_c('h3',{attrs:{"tabindex":"-1"}},[_vm._v(" 4.1 Estimate of costs and fees ")]):_vm._e(),(_vm.readonly === 'true')?_c('h4',[_vm._v("4.1 Estimate of costs and fees")]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('PortalMoneyField',{ref:"estimateTotalCosts",attrs:{"label":"The estimate of total costs and fees at the commencement of the event","data":_vm.estimateTotalCosts,"labelClass":{ 'form-group--error': _vm.$v.estimateTotalCosts.$error },"md":"9","readonly":_vm.readonly,"state":_vm.validateState(_vm.$v.estimateTotalCosts),"required":"true","invalidFeedback":"This is a required field and must be a valid amount."},on:{"change":function($event){_vm.$emit('estimate-total-costs-change', $event);
          _vm.$v.estimateTotalCosts.$touch();}}}),_c('PortalMoneyField',{ref:"estimatePartyCosts",attrs:{"label":"How much of the estimate was party/party costs?","data":_vm.estimatePartyCosts,"labelClass":{ 'form-group--error': _vm.$v.estimatePartyCosts.$error },"md":"9","readonly":_vm.readonly,"state":_vm.validateState(_vm.$v.estimatePartyCosts),"required":"true","invalidFeedback":"This is a required field and must be a valid amount."},on:{"change":function($event){_vm.$emit('estimate-party-costs-change', $event);
          _vm.$v.estimatePartyCosts.$touch();}}}),_c('PortalMoneyField',{ref:"estimateLegalFees",attrs:{"label":"How much of the estimate was legal fees?","data":_vm.estimateLegalFees,"labelClass":{ 'form-group--error': _vm.$v.estimateLegalFees.$error },"md":"9","readonly":_vm.readonly,"state":_vm.validateState(_vm.$v.estimateLegalFees),"required":"true","invalidFeedback":"This is a required field and must be a valid amount."},on:{"change":function($event){_vm.$emit('estimate-legal-fees-change', $event);
          _vm.$v.estimateLegalFees.$touch();}}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(_vm.readonly === 'false')?_c('h3',[_vm._v("4.2 Costs and disbursements")]):_vm._e(),(_vm.readonly === 'true')?_c('h4',[_vm._v("4.2 Costs and disbursements")]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('PortalMoneyField',{ref:"solicitorClientFees",attrs:{"label":"Solicitor client fees (excluding party/party costs)","data":_vm.solicitorClientFees,"labelClass":{ 'form-group--error': _vm.$v.solicitorClientFees.$error },"md":"9","readonly":_vm.readonly,"state":_vm.validateState(_vm.$v.solicitorClientFees),"required":"true","invalidFeedback":"This is a required field and must be a valid amount."},on:{"change":function($event){_vm.$emit('solicitor-client-fees-change', $event);
          _vm.$v.solicitorClientFees.$touch();}}}),_c('PortalMoneyField',{ref:"partyCosts",attrs:{"label":"Party/party costs (excluding solicitor client fees)","data":_vm.partyCosts,"labelClass":{ 'form-group--error': _vm.$v.partyCosts.$error },"md":"9","readonly":_vm.readonly,"state":_vm.validateState(_vm.$v.partyCosts),"required":"true","invalidFeedback":"This is a required field and must be a valid amount."},on:{"change":function($event){_vm.$emit('party-costs-change', $event);
          _vm.$v.partyCosts.$touch();}}}),_c('PortalMoneyField',{ref:"previousLawyerLegalFees",attrs:{"label":"Previous lawyer legal fees (if any)","data":_vm.previousLawyerLegalFees,"labelClass":{
          'form-group--error': _vm.$v.previousLawyerLegalFees.$error,
        },"md":"9","readonly":_vm.readonly,"state":_vm.validateState(_vm.$v.previousLawyerLegalFees),"required":"true","invalidFeedback":"This is a required field and must be a valid amount."},on:{"change":function($event){_vm.$emit('previous-lawyer-legal-fees-change', $event);
          _vm.$v.previousLawyerLegalFees.$touch();}}}),_c('PortalMoneyField',{ref:"barristerCounselFees",attrs:{"label":"Barrister / counsel fees","data":_vm.barristerCounselFees,"labelClass":{ 'form-group--error': _vm.$v.barristerCounselFees.$error },"md":"9","readonly":_vm.readonly,"state":_vm.validateState(_vm.$v.barristerCounselFees),"required":"true","invalidFeedback":"This is a required field and must be a valid amount."},on:{"change":function($event){_vm.$emit('barrister-counsel-fees-change', $event);
          _vm.$v.barristerCounselFees.$touch();}}}),_c('PortalMoneyField',{ref:"attendingGPFees",attrs:{"label":"Fees for Medical reports and clinical notes, including allied health","data":_vm.attendingGPFees,"labelClass":{ 'form-group--error': _vm.$v.attendingGPFees.$error },"md":"9","readonly":_vm.readonly,"state":_vm.validateState(_vm.$v.attendingGPFees),"required":"true","invalidFeedback":"This is a required field and must be a valid amount."},on:{"change":function($event){_vm.$emit('attending-gp-fees-change', $event);
          _vm.$v.attendingGPFees.$touch();}}}),_c('PortalMoneyField',{ref:"medicoLegalSpecialistReports",attrs:{"label":"Medico-legal specialist reports including allied health","data":_vm.medicoLegalSpecialistReports,"labelClass":{
          'form-group--error': _vm.$v.medicoLegalSpecialistReports.$error,
        },"md":"9","readonly":_vm.readonly,"state":_vm.validateState(_vm.$v.medicoLegalSpecialistReports),"required":"true","invalidFeedback":"This is a required field and must be a valid amount."},on:{"change":function($event){_vm.$emit('medico-legal-specialist-reports-change', $event);
          _vm.$v.medicoLegalSpecialistReports.$touch();}}}),_c('PortalMoneyField',{ref:"otherExpertFees",attrs:{"label":"Other expert fees paid","data":_vm.otherExpertFees,"labelClass":{ 'form-group--error': _vm.$v.otherExpertFees.$error },"md":"9","readonly":_vm.readonly,"state":_vm.validateState(_vm.$v.otherExpertFees),"required":"true","invalidFeedback":"This is a required field and must be a valid amount."},on:{"change":function($event){_vm.$emit('other-expert-fees-change', $event);
          _vm.$v.otherExpertFees.$touch();}}}),_c('PortalMoneyField',{ref:"otherFees",attrs:{"label":"Other e.g. interpreter, travel, application and filing fees, witness expenses, etc.","data":_vm.otherFees,"labelClass":{ 'form-group--error': _vm.$v.otherFees.$error },"md":"9","readonly":_vm.readonly,"state":_vm.validateState(_vm.$v.otherFees),"required":"true","invalidFeedback":"This is a required field and must be a valid amount."},on:{"change":function($event){_vm.$emit('other-fees-change', $event);
          _vm.$v.otherFees.$touch();}}}),_c('b-form-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Total all costs","label-size":"sm","label-for":"totalCosts","label-cols-md":"9","label-cols-sm":"12"}},[_c('b-form-input',{attrs:{"size":"sm","type":"text","readonly":"","id":"totalCosts"},model:{value:(_vm.totalCosts),callback:function ($$v) {_vm.totalCosts=$$v},expression:"totalCosts"}})],1)],1)],1),(!_vm.isCommonLaw)?_c('PortalBooleanField',{ref:"acatCostOrder",attrs:{"label":"Was there a cost order under the Motor Accident Injuries (ACAT Costs Orders) Regulation 2020?","data":_vm.acatCostOrder,"md":"9","type":"text","labelClass":{
          'form-group--error': _vm.$v.acatCostOrder && _vm.$v.acatCostOrder.$error,
        },"readonly":_vm.readonly === 'true' || _vm.isCommonLaw ? 'true' : 'false',"state":_vm.validateState(_vm.$v.acatCostOrder),"required":"true"},on:{"change":function($event){_vm.$emit('acat-cost-order-change', $event);
          _vm.$v.acatCostOrder.$touch();}}}):_vm._e()],1)]),(_vm.isCommonLaw)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(_vm.readonly === 'false')?_c('h3',[_vm._v("4.3 Deductions")]):_vm._e(),(_vm.readonly === 'true')?_c('h4',[_vm._v("4.3 Deductions")]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('PortalMoneyField',{ref:"medicarePayback",attrs:{"label":"Medicare payback","data":_vm.medicarePayback,"labelClass":{
            'form-group--error': _vm.$v.medicarePayback.$error,
          },"md":"9","readonly":_vm.readonly,"state":_vm.validateState(_vm.$v.medicarePayback),"required":"true","invalidFeedback":"This is a required field and must be a valid amount."},on:{"change":function($event){_vm.$emit('medicare-payback-change', $event);
            _vm.$v.medicarePayback.$touch();}}}),_c('PortalMoneyField',{ref:"centrelinkPayback",attrs:{"label":"Centrelink payback","data":_vm.centrelinkPayback,"labelClass":{
            'form-group--error': _vm.$v.centrelinkPayback.$error,
          },"md":"9","readonly":_vm.readonly,"state":_vm.validateState(_vm.$v.centrelinkPayback),"required":"true","invalidFeedback":"This is a required field and must be a valid amount."},on:{"change":function($event){_vm.$emit('centrelink-payback-change', $event);
            _vm.$v.centrelinkPayback.$touch();}}}),_c('PortalMoneyField',{ref:"privateHealthInsurerPayback",attrs:{"label":"Other insurer payback","data":_vm.privateHealthInsurerPayback,"labelClass":{
            'form-group--error': _vm.$v.privateHealthInsurerPayback.$error,
          },"md":"9","readonly":_vm.readonly,"state":_vm.validateState(_vm.$v.privateHealthInsurerPayback),"required":"true","invalidFeedback":"This is a required field and must be a valid amount."},on:{"change":function($event){_vm.$emit('private-health-insurer-payback-change', $event);
            _vm.$v.otherInsurerPayback.$touch();}}}),_c('PortalMoneyField',{ref:"advancePaymentClient",attrs:{"label":"Advance payment to client","data":_vm.advancePaymentClient,"labelClass":{
            'form-group--error': _vm.$v.advancePaymentClient.$error,
          },"md":"9","readonly":_vm.readonly,"state":_vm.validateState(_vm.$v.advancePaymentClient),"required":"true","invalidFeedback":"This is a required field and must be a valid amount."},on:{"change":function($event){_vm.$emit('advance-payment-client-change', $event);
            _vm.$v.advancePaymentClient.$touch();}}}),_c('b-form-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Total all deductions","label-size":"sm","label-for":"totalDeductions","label-cols-md":"9","label-cols-sm":"12"}},[_c('b-form-input',{attrs:{"size":"sm","type":"text","readonly":"","id":"totalDeductions"},model:{value:(_vm.totalDeductions),callback:function ($$v) {_vm.totalDeductions=$$v},expression:"totalDeductions"}})],1)],1)],1)],1)])]):_vm._e(),(_vm.isCommonLaw)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(_vm.readonly === 'false')?_c('h3',[_vm._v("4.4 Distribution of funds")]):_vm._e(),(_vm.readonly === 'true')?_c('h4',[_vm._v("4.4 Distribution of funds")]):_vm._e()])]):_vm._e(),(_vm.isCommonLaw)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('PortalMoneyField',{ref:"netAmountDueClaimant",attrs:{"label":"Net amount due to client","data":_vm.netAmountDueClaimant,"labelClass":{ 'form-group--error': _vm.$v.netAmountDueClaimant.$error },"readonly":_vm.readonly,"state":_vm.validateState(_vm.$v.netAmountDueClaimant),"md":"9","required":"true","invalidFeedback":"This is a required field and must be a valid amount."},on:{"change":function($event){_vm.$emit('net-amount-due-claimant-change', $event);
          _vm.$v.netAmountDueClaimant.$touch();}}}),_c('PortalDateField',{ref:"claimantPaymentDate",attrs:{"label":"If paid, what was the date the balance of funds was distributed to the client?","data":_vm.claimantPaymentDate,"labelClass":{ 'form-group--error': _vm.$v.claimantPaymentDate.$error },"readonly":_vm.readonly,"state":_vm.validateState(_vm.$v.claimantPaymentDate),"md":"9","invalidFeedback":"This must be empty or a valid date before today."},on:{"change":function($event){_vm.$emit('claimant-payment-date-change', $event);
          _vm.$v.claimantPaymentDate.$touch();}}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }