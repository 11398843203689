import { ILawyerService } from "../services/ILawyer.service";
import { injectable, inject } from "inversify";

import { AxiosResponse } from 'axios';
import '../common/maisLawyerServiceInterface';

@injectable()
export class MockLawyerService implements ILawyerService {

    getUrl(): string {
        return 'Mock';
    }
    searchMAISClaims(request: MAISLawyerService.SearchClaimRequest): Promise<AxiosResponse<MAISLawyerService.SearchClaimResponse>> {
        let r = {} as AxiosResponse<MAISLawyerService.SearchClaimResponse>;
        r.data = {} as MAISLawyerService.SearchClaimResponse;
        r.data.success = true;
        r.data.secret = 'secret';
        if (request.claimId === "0") {
            r.data.resultCount = 0;
        }
        if (request.claimId === "1") {
            r.data.resultCount = 1;
        }
        if (request.claimId === "2") {
            r.data.resultCount = 2;
        }
        return Promise.resolve(r);

    }
    submitLawyerLodgement(request: MAISLawyerService.LawyerLodgement, claimUnverified: boolean): Promise<AxiosResponse<MAISLawyerService.InsertLawyerLodgementResponse>> {
        let r = {} as AxiosResponse<MAISLawyerService.InsertLawyerLodgementResponse>;
        r.data = {} as MAISLawyerService.InsertLawyerLodgementResponse;
        r.data.success = true;
        r.data.transactionId = '1234-5678-1234';
        r.data.submittedAt = new Date().toString();

        return Promise.resolve(r);

    }


}
