import * as type from './types'

export default {
  state: {
    transactionId: null,
    submittedAt: null,
    legalRepFirstName: null,
    legalRepLastName: null,
    legalRepPhoneNumber: null,
    legalRepEmail: null,
    managingInsurer: null,
    claimId: null,
    accidentDate: null,
    accidentPostCode: null,
    claimantDateOfBirth: null,
    estimateTotalCosts: null,
    estimatePartyCosts: null,
    estimateLegalFees: null,
    solicitorClientFees: null,
    partyCosts: null,
    previousLawyerLegalFees: null,
    barristerCounselFees: null,
    attendingGPFees: null,
    medicoLegalSpecialistReports: null,
    otherExpertFees: null,
    otherFees: null,
    acatCostOrder: null,
    netAmountDueClaimant: null,
    claimantPaymentDate: null,
    proceedingType: null,
    decisionSettlementDate: null,
    feedbackForMAIC: null,
    insurerFirstOfferInclusiveLegalCosts: null,
    insurerFirstSettlementOffer: null,
    insurerFirstOfferDate: null,
    claimantFirstSettlementOffer: null,
    claimantFirstOfferInclusiveLegalCostDisbursements: null,
    claimantFirstOfferDate: null,
    medicarePayback: null,
    centrelinkPayback: null,
    privateHealthInsurerPayback: null,
    advancePaymentClient: null
  },
  mutations: {
    [type.SAVE_SUBMISSION](state, payload) {
      state.transactionId = payload.transactionId;
      state.submittedAt = payload.submittedAt;
      state.legalRepFirstName = payload.legalRepFirstName;
      state.legalRepLastName = payload.legalRepLastName;
      state.legalRepPhoneNumber = payload.legalRepPhoneNumber;
      state.legalRepEmail = payload.legalRepEmail;
      state.managingInsurer = payload.managingInsurer;
      state.claimId = payload.claimId;
      state.accidentDate = payload.accidentDate;
      state.accidentPostCode = payload.accidentPostCode;
      state.claimantDateOfBirth = payload.claimantDateOfBirth;
      state.estimateTotalCosts = payload.estimateTotalCosts;
      state.estimatePartyCosts = payload.estimatePartyCosts;
      state.estimateLegalFees = payload.estimateLegalFees;
      state.solicitorClientFees = payload.solicitorClientFees;
      state.partyCosts = payload.partyCosts;
      state.previousLawyerLegalFees = payload.previousLawyerLegalFees;
      state.barristerCounselFees = payload.barristerCounselFees;
      state.attendingGPFees = payload.attendingGPFees;
      state.medicoLegalSpecialistReports = payload.medicoLegalSpecialistReports;
      state.otherExpertFees = payload.otherExpertFees;
      state.otherFees = payload.otherFees;
      state.acatCostOrder = payload.acatCostOrder;
      state.netAmountDueClaimant = payload.netAmountDueClaimant;
      state.claimantPaymentDate = payload.claimantPaymentDate;
      state.proceedingType = payload.proceedingType;
      state.decisionSettlementDate = payload.decisionSettlementDate;
      state.feedbackForMAIC = payload.feedbackForMAIC;
      state.insurerFirstOfferInclusiveLegalCosts = payload.insurerFirstOfferInclusiveLegalCosts;
      state.insurerFirstSettlementOffer = payload.insurerFirstSettlementOffer;
      state.insurerFirstOfferDate = payload.insurerFirstOfferDate;
      state.claimantFirstSettlementOffer = payload.claimantFirstSettlementOffer;
      state.claimantFirstOfferInclusiveLegalCostDisbursements = payload.claimantFirstOfferInclusiveLegalCostDisbursements;
      state.claimantFirstOfferDate = payload.claimantFirstOfferDate;
      state.medicarePayback = payload.medicarePayback;
      state.centrelinkPayback = payload.centrelinkPayback;
      state.privateHealthInsurerPayback = payload.privateHealthInsurerPayback;
      state.advancePaymentClient = payload.advancePaymentClient;

    }
  },
  actions: {
    [type.GET_SUBMISSION](state, payload) {
      alert('action');
    }
  }
}

