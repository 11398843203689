

































































































































































































































































































































































































import { Component, Prop, Vue, Model } from "vue-property-decorator";
import PortalBooleanField from "@/components/PortalBooleanField.vue";
import PortalDateField from "@/components/PortalDateField.vue";
import PortalMoneyField from "@/components/PortalMoneyField.vue";
import router from "../router";
import { required } from "vuelidate/lib/validators";
import { Validations } from "vuelidate-property-decorators";
import { helpers } from "vuelidate/lib/validators";
import { date, focusFirstError, money } from "@/common/validation";

@Component({
  components: {
    PortalDateField,
    PortalMoneyField,
    PortalBooleanField,
  },
})
export default class ClaimSubmit extends Vue {
  @Validations()
  validations() {
    let validations = {} as any;
    validations = {
      estimateTotalCosts: { required, money },
      estimatePartyCosts: { required, money },
      estimateLegalFees: { required, money },

      solicitorClientFees: { required, money },
      partyCosts: { required, money },
      previousLawyerLegalFees: { required, money },
      barristerCounselFees: { required, money },
      attendingGPFees: { required, money },
      medicoLegalSpecialistReports: { required, money },
      otherExpertFees: { required, money },
      otherFees: { required, money },
      claimantPaymentDate: {
        date: date(new Date().toISOString().substring(0, 10)),
      },
    };

    if (this.isCommonLaw) {
      validations.netAmountDueClaimant = { required, money };
      validations.medicarePayback = { required, money };
      validations.centrelinkPayback = { required, money };
      validations.advancePaymentClient = { required, money };
      validations.privateHealthInsurerPayback = { required, money };
    } else {
      validations.acatCostOrder = { required };
    }
    return validations;
  }

  @Prop()
  private readonly proceedingType!: string;

  @Prop()
  private readonly estimateTotalCosts!: string;

  @Prop()
  private estimatePartyCosts!: string;

  @Prop()
  private estimateLegalFees!: string;

  @Prop()
  private solicitorClientFees!: string;

  @Prop()
  private partyCosts!: string;

  @Prop()
  private previousLawyerLegalFees!: string;

  @Prop()
  private barristerCounselFees!: string;

  @Prop()
  private attendingGPFees!: string;

  @Prop()
  private medicoLegalSpecialistReports!: string;

  @Prop()
  private otherExpertFees!: string;

  @Prop()
  private otherFees!: string;

  @Prop()
  private acatCostOrder!: string;

  @Prop() private readonly: string;

  @Prop()
  private netAmountDueClaimant!: string;

  @Prop()
  private claimantPaymentDate!: string;

  @Prop()
  private medicarePayback!: string;

  @Prop()
  private centrelinkPayback!: string;

  @Prop()
  private privateHealthInsurerPayback!: string;

  @Prop()
  private advancePaymentClient!: string;

  private $v: any;

  get totalCosts(): string {
    return (
      (parseFloat(this.solicitorClientFees) || 0) +
      (parseFloat(this.partyCosts) || 0) +
      (parseFloat(this.previousLawyerLegalFees) || 0) +
      (parseFloat(this.barristerCounselFees) || 0) +
      (parseFloat(this.attendingGPFees) || 0) +
      (parseFloat(this.medicoLegalSpecialistReports) || 0) +
      (parseFloat(this.otherExpertFees) || 0) +
      (parseFloat(this.otherFees) || 0)
    ).toFixed(2);
  }

  get totalDeductions(): string {
    return (
      (parseFloat(this.medicarePayback) || 0) +
      (parseFloat(this.centrelinkPayback) || 0) +
      (parseFloat(this.privateHealthInsurerPayback) || 0) +
      (parseFloat(this.advancePaymentClient) || 0)
    ).toFixed(2);
  }
  async validate() {
    this.$v.$touch();
    var isValid = !this.$v.$invalid;
    if (!isValid) focusFirstError(this.$v, this.$refs);
    this.$emit("on-validate", this.$data, isValid);
    return Promise.resolve(isValid);
  }

  validateState(name) {
    return name.$dirty ? !name.$error : null;
  }

  get isCommonLaw(): boolean {
    return this.proceedingType === "C";
  }
}
