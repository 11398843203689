import { ILawyerService } from './ILawyer.service'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import '../common/maisLawyerServiceInterface';
import { injectable } from 'inversify';
import { getIPAddress } from "../common/helper";

@injectable()
export class LawyerService implements ILawyerService {

    constructor() {
    }

    getUrl(): string {
        return JSON.stringify(process.env.VUE_APP_LAWYER_SERVICE);
    }

    async searchMAISClaims(request: MAISLawyerService.SearchClaimRequest): Promise<AxiosResponse<MAISLawyerService.SearchClaimResponse>> {
        let params: AxiosRequestConfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        request.ipAddress = await getIPAddress();
        return axios.post<MAISLawyerService.SearchClaimResponse>(process.env.VUE_APP_LAWYER_SERVICE + '/v1/claims', request, params);
    }
    formatDate(d) {
        //format date as YYYY-MM-DD
        return new Date(d.getTime() - d.getTimezoneOffset() * 60000)
            .toISOString()
            .split("T")[0];
    }

    async submitLawyerLodgement(request: MAISLawyerService.LawyerLodgement, claimUnverified: boolean): Promise<AxiosResponse<MAISLawyerService.InsertLawyerLodgementResponse>> {

        let newRequest = {} as MAISLawyerService.LawyerLodgement;
        Object.assign(newRequest, request);
        newRequest.accidentDate = this.formatDate(request.accidentDate);
        newRequest.claimantDateOfBirth = this.formatDate(request.claimantDateOfBirth);
        newRequest.lodgementStatus = claimUnverified ? 'APPLICATION_UNVERIFIED' : 'APPLICATION_VERIFIED';
        delete newRequest['number1'];
        delete newRequest['number2'];
        delete newRequest['correctAnswer'];
        delete newRequest['simpleAnswer'];

        let params: AxiosRequestConfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        return axios.post<MAISLawyerService.InsertLawyerLodgementResponse>(process.env.VUE_APP_LAWYER_SERVICE + '/v1/lawyerLodgements', newRequest, params);
    }
}
