











































































import { Component, Prop, Vue, Model } from "vue-property-decorator";
import router from "../router";
import { ClaimSearchParams } from "../stores/claimSearchParams";
import PortalDataField from "@/components/PortalDataField.vue";
import PortalDateField from "@/components/PortalDateField.vue";
import PortalNumberField from "@/components/PortalNumberField.vue";
import {
  integer,
  required,
  minLength,
  between,
} from "vuelidate/lib/validators";
import "reflect-metadata";
import IOCContainer from "@/common/inversify.config";
import { TYPES } from "@/common/types";
import { ILawyerService } from "../services/ILawyer.service";
import "../common/maisLawyerServiceInterface";

import { Validate } from "vuelidate-property-decorators";
import { date, focusFirstError } from "@/common/validation.ts";

@Component({
  components: {
    PortalDataField,
    PortalDateField,
    PortalNumberField,
  },
})
export default class ClaimVerify extends Vue {
  @Validate({ required })
  @Prop()
  private managingInsurer!: string;

  @Validate({ required })
  @Prop()
  private claimId!: string;

  @Validate({
    required,
    date: date(new Date().toISOString().substring(0, 10)),
  })
  @Prop()
  private accidentDate!: Date;

  @Validate({ required, integer })
  @Prop()
  private accidentPostCode!: string;

  @Validate({ required, date: date(new Date().toISOString().substring(0, 10)) })
  @Prop()
  private claimantDateOfBirth!: Date;

  @Prop() private readonly: string;

  @Prop()
  private verificationStepPerformed: boolean;

  @Prop()
  private claimUnverified: boolean;

  @Prop()
  private correctAnswer: number;

  @Prop()
  private simpleAnswer: number;

  @Prop()
  private secret!: string;

  private showSpinner: boolean = false;

  private $v: any;

  formateDate(d: Date): string {
    //format date as YYYY-MM-DD
    return new Date(d.getTime() - d.getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0];
  }
  async validate() {
    try {
      this.showSpinner = true;
      this.$v.$touch();
      var isValid = !this.$v.$invalid;

      if (!isValid) {
        focusFirstError(this.$v, this.$refs);
      } else {
        if (!this.claimUnverified) {
          let lawyerService = IOCContainer.get<ILawyerService>(
            TYPES.LawyerService
          );
          const request = {} as MAISLawyerService.SearchClaimRequest;
          request.claimId = this.claimId;
          request.accidentDate = this.formateDate(this.accidentDate);
          request.accidentPostCode = this.accidentPostCode;
          request.claimantDateOfBirth = this.formateDate(
            this.claimantDateOfBirth
          );

          const response = await lawyerService.searchMAISClaims(request);
          this.showSpinner = false;
          if (response.data.success) {
            if (response.data.resultCount === 0) {
              isValid = false;
              this.$emit("secretChange", response.data.secret);
              this.$bvModal.show("modal-notFound");
            } else if (response.data.resultCount > 1) {
              isValid = false;
              this.$bvModal.show("modal-tooMany");
            }
          }
          if (isValid) {
            this.$emit("secretChange", response.data.secret);
          }
        }
      }
      this.showSpinner = false;
      return Promise.resolve(isValid);
    } catch (err) {
      if (err.code === "ECONNABORTED") {
        this.showSpinner = false;
        this.$bvModal.show("modal-timeout");
      } else {
        router.push({ name: "500" });
      }
    }
  }

  validateState(name) {
    return name.$dirty ? !name.$error : null;
  }
}
