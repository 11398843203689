import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, BIcon, BIconCalendar, BIconCalendarFill } from 'bootstrap-vue'
import Multiselect from 'vue-multiselect'
import store from './stores/index'
import Vuelidate from 'vuelidate'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown, faChevronRight, faCopy, faSearch, faExternalLinkAlt, faInfoCircle, faCircle, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import vueHeadful from 'vue-headful';

// Import the plugin here
import VueClipboard from 'vue-clipboard2'

library.add(faChevronDown, faChevronRight, faCopy, faSearch, faExternalLinkAlt, faInfoCircle, faCircle, faFilePdf)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('vue-headful', vueHeadful);

// register globally
Vue.component('multiselect', Multiselect)
Vue.use(BootstrapVue)
Vue.component('BIcon', BIcon)
Vue.component('BIconCalendarFill', BIconCalendarFill)
Vue.component('BIconCalendar', BIconCalendar)

Vue.use(Vuelidate)
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
