import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Submission from '../views/Submission.vue'
import ClaimSubmitted from '../views/ClaimSubmitted.vue'
import ErrorView from '../views/Error.vue'
import NotFound from '../views/NotFound.vue'
import "reflect-metadata";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/Submission',
    name: 'submission',
    component: Submission,
    meta: { pageName: 'Submit details' }
  },
  {
    path: '/Claim/Submitted',
    name: 'claimSubmitted',
    component: ClaimSubmitted,
    meta: { pageName: 'Submitted' }
  },
  {
    path: '/404', name: '404', component: NotFound,
    meta: { pageName: 'Page not found' }
  },
  { path: '/500', name: '500', component: ErrorView, meta: { pageName: 'Error' } },
  { path: '*', redirect: '/404' }]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
