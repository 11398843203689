var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home pb-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(_vm.readonly === 'false')?_c('h2',{attrs:{"id":"header-3","tabindex":"-1"}},[_vm._v(" 3. Event type ")]):_vm._e(),(_vm.readonly === 'true')?_c('h3',[_vm._v("3. Event type")]):_vm._e()])]),(_vm.claimUnverified)?_c('div',{staticClass:"hidden"},[_c('div',[_c('PortalTextField',{ref:"lastNameSpamFilter",attrs:{"label":"Last name (to be hidden)","data":_vm.lastNameSpamFilter,"type":"text","readonly":_vm.readonly === 'true' ? 'true' : 'false'},on:{"change":function($event){return _vm.$emit('last-name-spam-filter-change', $event)}}})],1)]):_vm._e(),(_vm.claimUnverified)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('PortalNumberField',{ref:"simpleAnswer",attrs:{"label":_vm.simpleLabel,"data":_vm.simpleAnswer,"tooltip":"This is an additional human verification step for an unverified application","type":"text","labelClass":{
          'form-group--error': _vm.$v.simpleAnswer && _vm.$v.simpleAnswer.$error,
        },"readonly":_vm.readonly === 'true' ? 'true' : 'false',"state":_vm.validateState(_vm.$v.simpleAnswer),"invalidFeedback":"This is a required field and must be the correct answer.","required":"true"},on:{"change":function($event){_vm.$emit('simple-answer-change', $event);
          _vm.$v.simpleAnswer && _vm.$v.simpleAnswer.$touch();}}})],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-form-group',{class:{ 'form-group--error': _vm.$v.proceedingType.$error },attrs:{"label":"Matter represented *","label-for":"proceedingType","label-cols-md":"6","label-cols-sm":"12","label-size":"sm"}},[_c('b-form-select',{ref:"proceedingType",attrs:{"size":"sm","value":_vm.proceedingType,"options":_vm.proceedingTypeOptions,"id":"proceedingType","disabled":_vm.readonly === 'true',"state":_vm.validateState(_vm.$v.proceedingType),"labelClass":{
            'form-group--error':
              _vm.$v.proceedingType && _vm.$v.proceedingType.$error,
          },"aria-describedby":"proceedingTypelive-feedback"},on:{"change":function($event){return _vm.$emit('proceeding-type-change', $event)}}}),_c('b-form-invalid-feedback',{attrs:{"id":"proceedingTypelive-feedback"}},[_vm._v("This is a required field")])],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('PortalDateField',{ref:"decisionSettlementDate",attrs:{"label":"Decision or settlement date","data":_vm.decisionSettlementDate,"labelClass":{
          'form-group--error':
            _vm.$v.decisionSettlementDate && _vm.$v.decisionSettlementDate.$error,
        },"readonly":_vm.readonly === 'true' ? 'true' : 'false',"state":_vm.validateState(_vm.$v.decisionSettlementDate),"required":"true","invalidFeedback":"This is a required field and must be a valid date before today."},on:{"change":function($event){_vm.$emit('decision-settlement-date-change', $event);
          _vm.$v.decisionSettlementDate && _vm.$v.decisionSettlementDate.$touch();}}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('PortalTextField',{ref:"feedbackForMAIC",attrs:{"label":"Feedback for MAIC regarding the matter","data":_vm.feedbackForMAIC,"type":"text","labelClass":{
          'form-group--error':
            _vm.$v.feedbackForMAIC && _vm.$v.feedbackForMAIC.$error,
        },"readonly":_vm.readonly === 'true' ? 'true' : 'false'},on:{"change":function($event){_vm.$emit('feedback-for-maic-change', $event);
          _vm.$v.feedbackForMAIC && _vm.$v.feedbackForMAIC.$touch();}}})],1)]),(_vm.isCommonLaw)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(_vm.readonly === 'false')?_c('h3',[_vm._v("3.1 Settlement offer")]):_vm._e(),(_vm.readonly === 'true')?_c('h4',[_vm._v("3.1 Settlement offer")]):_vm._e()])]):_vm._e(),(_vm.isCommonLaw)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('PortalMoneyField',{ref:"insurerFirstSettlementOffer",attrs:{"label":"Insurer's first offer of settlement?","data":_vm.insurerFirstSettlementOffer,"labelClass":{
          'form-group--error':
            _vm.$v.insurerFirstSettlementOffer &&
            _vm.$v.insurerFirstSettlementOffer.$error,
        },"readonly":_vm.readonly === 'true' || !_vm.isCommonLaw ? 'true' : 'false',"state":_vm.validateState(_vm.$v.insurerFirstSettlementOffer),"required":"true","invalidFeedback":"This is a required field and must be a valid amount."},on:{"change":function($event){_vm.$emit('insurer-first-settlement-offer-change', $event);
          _vm.$v.insurerFirstSettlementOffer &&
            _vm.$v.insurerFirstSettlementOffer.$touch();}}})],1)]):_vm._e(),(_vm.isCommonLaw)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('PortalBooleanField',{ref:"insurerFirstOfferInclusiveLegalCosts",attrs:{"label":"Is this inclusive of legal costs?","data":_vm.insurerFirstOfferInclusiveLegalCosts,"type":"text","labelClass":{
          'form-group--error':
            _vm.$v.insurerFirstOfferInclusiveLegalCosts &&
            _vm.$v.insurerFirstOfferInclusiveLegalCosts.$error,
        },"readonly":_vm.readonly === 'true' || !_vm.isCommonLaw ? 'true' : 'false',"state":_vm.validateState(_vm.$v.insurerFirstOfferInclusiveLegalCosts),"required":"true"},on:{"change":function($event){_vm.$emit('insurer-first-offer-inclusive-legal-costs-change', $event);
          _vm.$v.insurerFirstOfferInclusiveLegalCosts &&
            _vm.$v.insurerFirstOfferInclusiveLegalCosts.$touch();}}})],1)]):_vm._e(),(_vm.isCommonLaw)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('PortalDateField',{ref:"insurerFirstOfferDate",attrs:{"label":"Date of insurer's first offer","data":_vm.insurerFirstOfferDate,"labelClass":{
          'form-group--error':
            _vm.$v.insurerFirstOfferDate && _vm.$v.insurerFirstOfferDate.$error,
        },"readonly":_vm.readonly === 'true' || !_vm.isCommonLaw ? 'true' : 'false',"state":_vm.validateState(_vm.$v.insurerFirstOfferDate),"required":"true","invalidFeedback":"This is a required field and must be a valid date before today."},on:{"change":function($event){_vm.$emit('insurer-first-offer-date-change', $event);
          _vm.$v.insurerFirstOfferDate && _vm.$v.insurerFirstOfferDate.$touch();}}})],1)]):_vm._e(),(_vm.isCommonLaw)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('PortalMoneyField',{ref:"claimantFirstSettlementOffer",attrs:{"label":"Claimant's first offer of settlement?","data":_vm.claimantFirstSettlementOffer,"labelClass":{
          'form-group--error':
            _vm.$v.claimantFirstSettlementOffer &&
            _vm.$v.claimantFirstSettlementOffer.$error,
        },"readonly":_vm.readonly === 'true' || !_vm.isCommonLaw ? 'true' : 'false',"state":_vm.validateState(_vm.$v.claimantFirstSettlementOffer),"required":"true","invalidFeedback":"This is a required field and must be a valid amount."},on:{"change":function($event){_vm.$emit('claimant-first-settlement-offer-change', $event);
          _vm.$v.claimantFirstSettlementOffer &&
            _vm.$v.claimantFirstSettlementOffer.$touch();}}})],1)]):_vm._e(),(_vm.isCommonLaw)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('PortalBooleanField',{ref:"claimantFirstOfferInclusiveLegalCostDisbursements",attrs:{"label":"Inclusive of legal cost and disbursements?","data":_vm.claimantFirstOfferInclusiveLegalCostDisbursements,"type":"text","labelClass":{
          'form-group--error':
            _vm.$v.claimantFirstOfferInclusiveLegalCostDisbursements &&
            _vm.$v.claimantFirstOfferInclusiveLegalCostDisbursements.$error,
        },"readonly":_vm.readonly === 'true' || !_vm.isCommonLaw ? 'true' : 'false',"state":_vm.validateState(_vm.$v.claimantFirstOfferInclusiveLegalCostDisbursements),"required":"true"},on:{"change":function($event){_vm.$emit('claimant-first-offer-inclusive-legal-cost-change', $event);
          _vm.$v.claimantFirstOfferInclusiveLegalCostDisbursements &&
            _vm.$v.claimantFirstOfferInclusiveLegalCostDisbursements.$touch();}}})],1)]):_vm._e(),(_vm.isCommonLaw)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('PortalDateField',{ref:"claimantFirstOfferDate",attrs:{"label":"Date of claimant's first offer","data":_vm.claimantFirstOfferDate,"labelClass":{
          'form-group--error':
            _vm.$v.claimantFirstOfferDate && _vm.$v.claimantFirstOfferDate.$error,
        },"readonly":_vm.readonly === 'true' || !_vm.isCommonLaw ? 'true' : 'false',"state":_vm.validateState(_vm.$v.claimantFirstOfferDate),"required":"true","invalidFeedback":"This is a required field and must be a valid date before today."},on:{"change":function($event){_vm.$emit('claimant-first-offer-date-change', $event);
          _vm.$v.claimantFirstOfferDate && _vm.$v.claimantFirstOfferDate.$touch();}}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }